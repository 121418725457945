.row {
  display: grid;
  grid-template-columns: 0fr 1fr;
  gap: 0 7px;

  padding-left: 8px;
}

.hint {
  margin-bottom: 24px !important;

  font-size: 11px;
}

.verticalLine {
  width: 3px;
  height: calc(100% - 20px);

  background: var(--palette-warning-focus);
}
