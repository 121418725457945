.slideContent {
  display: grid;
  grid-template-rows: 1fr auto;

  align-items: center;
  justify-content: center;

  height: 100%;
}

.slideImg {
  width: 100%;
  max-height: 438px;
}

.slideDescription {
  max-width: 466px;
  margin: 40px auto 20px;

  text-align: center;

  color: var(--palette-primary-disabled);

  font-size: 14px;
  line-height: 1.8;
}
