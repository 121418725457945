.toolbar {
  display: flex;

  margin-bottom: 30px;
}

.spacer {
  flex: 1 1 auto;
}

.toolbar button {
  margin-left: 10px;
}

.toolbar button:first-child {
  margin-left: 0;
}

.addBtn {
  width: 36px;
  padding: 0;
}

.buttons {
  display: flex;
}

.inputDate > div:first-child {
  flex-wrap: nowrap;
}

.pagination {
  overflow-x: auto;
}

.search {
  margin-right: 10px;
}

@media (max-width: 992px) {
  .buttons {
    display: none;
  }
}

@media (max-width: 768px) {
  .search {
    width: 100%;
  }

  .toolbar button {
    padding: 0 10px;

    font-size: 0;
  }

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
