.container {
  width: 25px;
}

.container.inProgress {
  width: 38px;
  height: 38px;
}

.container img {
  width: 100%;
}

.text {
  font-size: 12px;
}

.text span {
  font-size: 9px;
}
