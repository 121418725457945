/* Pipeline Card */

.card {
  display: grid;

  padding: 10px 24px;

  cursor: pointer;

  border: 1px solid var(--palette-tertiary-transparent);
  border-radius: 16px;
  background: var(--palette-default);
  grid-template-columns: 64px 1fr;
  gap: 12px;
}

.cardImageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 64px;

  border-radius: 100%;
  background: var(--palette-default-hover);
}

.cardImageWrapperWithBd {
  background: var(--palette-warning-transparent);
}

.cardImage {
  width: 32px;
  height: 32px;
}

.cardImageWithBd {
  filter: invert(100%);
}

.title {
  text-align: left;

  color: var(--palette-text-default);

  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  line-height: 130%;
}

.subtitle {
  text-align: left;

  color: var(--palette-text-default);

  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  line-height: 130%;
}

/* Accessibility */

.card:focus {
  border-color: var(--palette-primary);
}

.card:hover {
  border-color: var(--palette-primary);
}

.card:active {
  border-color: var(--palette-primary-transparent);
  background: var(--palette-default-active);
}

.card:active .cardImageWrapper {
  background: var(--palette-default-transparent);
}

@media (max-width: 1200px) {
  .card {
    align-items: center;

    padding: 8px 14px;
    grid-template-columns: 42px 1fr;
    gap: 10px;
  }

  .cardImageWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 42px;

    border-radius: 100%;
    background: var(--palette-default-hover);
  }

  .cardImageWrapperWithBd {
    background: var(--palette-warning-transparent);
  }

  .cardImage {
    width: 20px;
    height: 20px;
  }

  .cardImageWithBd {
    filter: invert(100%);
  }

  .title {
    font-size: 16px;
  }

  .subtitle {
    font-size: 11px;
  }
}

@media (max-width: 992px) {
  .title {
    font-size: 15px;
  }

  .subtitle {
    font-size: 11px;
  }
}
