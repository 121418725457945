.title {
  margin-bottom: 12px;

  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
}

.subtitle {
  color: var(--palette-text-secondary);

  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
}

.table {
  overflow-y: auto;

  padding: 6px;

  border-radius: 10px;
  background-color: var(--palette-body);
}

.cell,
.content > div > div:last-of-type:not(:first-of-type) {
  margin-right: auto;
}

.value {
  color: var(--palette-text-link);
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 24px;
}

.buttons > button:last-child {
  margin-left: 34px;
}

.tableTitle {
  margin: 24px 0 12px 0;

  text-transform: uppercase;

  color: var(--palette-text-secondary);

  font-weight: 500;
}

.loader {
  z-index: 1;
}
