.wrapper {
  position: relative;
}

.tableHeader {
  position: absolute;
  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
}

.tableHeader button {
  transform: translateY(-250%);
}

.addBtn:hover {
  background-color: var(--button-primary-active-background) !important;
}

.addBtn:focus {
  background-color: var(--button-primary-background) !important;
}

@media (max-width: 768px) {
  button.addBtn {
    padding: 0 10px;
  }

  .addBtnText {
    display: none;
  }
}
