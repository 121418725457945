.toolbar {
  display: flex;
  align-items: center;

  margin-bottom: 30px;
}

.spacer {
  flex: 1 1 auto;
}

.search {
  margin-right: 10px;
}

@media (max-width: 1024px) {
  .search {
    width: 300px !important;
  }
}
