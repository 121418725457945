.sidebarWrapper {
  width: auto !important;
}

@media (max-width: 992px) {
  .sidebar.collapsed {
    left: -100% !important;
  }
}

/* toggler */

.toggler {
  position: absolute;
  top: 78px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 18px;
  height: 18px;
  padding: 4px;

  cursor: pointer;
  transition: ease-in-out 0.4s;
  transform: rotate(0);

  border: none;
  border-radius: 50%;
  background: var(--sidebar-toggler-background);
}

.toggler.left {
  right: -9px;

  transform: rotate(180deg);
}

.toggler.collapsed {
  transform: rotate(0);
}

.togglerImage {
  width: 100%;
}

/* Logo */

.logo {
  display: grid;
  overflow: hidden;
  align-items: center;

  margin-bottom: 42px;

  cursor: pointer;

  color: var(--palette-text-default);

  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  grid-template-columns: auto 1fr;
  gap: 12px;
}

.logo:hover {
  opacity: 0.8;
}

/* Menu */

.menu {
  display: grid;
  gap: 22px;

  overflow: hidden;

  padding-left: 8px;
}

/* Link */

.link {
  position: relative;

  display: flex;
  align-items: center;

  box-sizing: border-box;
  width: 100%;

  white-space: nowrap;
  text-decoration: none;

  color: var(--palette-text-secondary);

  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: 120%;
}

.link:hover,
.link:focus {
  text-decoration: underline;
}

.link img {
  margin: 0 6px 0 0;
  filter: grayscale(100%) brightness(150%);
}

.link.collapsed .linkText {
  visibility: hidden;

  width: 0;
}

/* Link active */

.activeLink {
  position: relative;

  color: var(--palette-text-default);
}

.activeLink img {
  filter: none;

  opacity: 1;
}

/* MenuLink */

.menuLinks {
  display: grid;
  gap: 22px;

  margin-top: 22px;
}

.link.collapsed .triggerText {
  visibility: hidden;

  width: 0;
}

.triggerText {
  margin-left: 8px;
}
