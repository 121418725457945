.search {
  margin-bottom: 30px;
}

.wrapper {
  padding: 48px;

  border-radius: 16px;
  background: var(--palette-default);
}

.wrapper:last-of-type {
  margin-top: 24px;
}

.noContentList {
  display: flex;
  align-items: center;
  justify-content: center;
}
