.container {
  padding: 48px;

  border-radius: 16px;
  background: var(--palette-default);
}

.uploadZone {
  margin-bottom: 30px;
}

.container .form {
  max-width: 600px;
  margin: 0 auto;
}

.fileList {
  display: grid;
  gap: 30px;
  grid-template-columns: 100%;
}

.buttons {
  display: flex;
  justify-content: flex-end;

  margin-top: 30px;
}

.buttons button:last-child {
  margin-left: 15px;
}

.label {
  text-transform: uppercase;

  color: var(--label-color);

  font-size: 12px;
}

.label ~ div {
  margin-top: 16px;
}

.tabWrapper {
  padding: 6px;

  border-radius: 16px;
  background-color: var(--palette-tertiary-disabled);
}

.textareaWrapper,
.fileWrapper {
  padding: 6px;

  border-radius: 0 8px 8px;
  background-color: var(--palette-default);
}

.fileWrapper > div,
.fileWrapper > div > div {
  margin-bottom: 0;
}

.radio {
  margin-bottom: 35px;
}

@media (max-width: 768px) {
  .container {
    padding: 24px;
  }
}

@media (max-width: 400px) {
  .container {
    padding: 20px 14px;
  }
}
