.title {
  margin-bottom: 24px;

  font-size: 22px;
  font-weight: 500;
  line-height: 130%;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-top: 36px;
}

.buttonsWrapper > button:last-child {
  margin-left: 16px;
}
