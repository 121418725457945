.container {
  padding: 24px 10px;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 30px;
}

.title {
  margin-bottom: 50px;

  font-size: 22px;
  font-weight: 500;
  line-height: 130%;
}

.buttons {
  margin-top: 20px;
}

.titlePopup {
  margin-bottom: 18px;

  text-align: center;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonsWrapper > button:last-child {
  margin-left: 16px;
}

.button {
  width: 220px;
}

.input {
  margin: 0 auto;
}

.modal {
  width: 600px !important;
  padding: 24px 74px !important;
}

.errorMsg {
  position: absolute;
  top: 28px;

  width: 80%;
  max-width: 80%;
}

.errorMsgPopup {
  left: 70px;
}

@media (max-width: 768px) {
  .row {
    grid-template-columns: 100%;
  }

  .modal {
    width: 100% !important;
    padding: 24px 40px !important;
  }

  .input {
    width: 100%;
  }
}
