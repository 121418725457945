.container {
  padding: 48px;

  border-radius: 16px;
  background: var(--palette-default);
}

.uploadZone {
  margin-bottom: 30px;
}

.container .form {
  display: flex;
  flex-direction: column;

  max-width: 700px;
  margin: 0 auto;
}

.fileList {
  display: grid;
  gap: 30px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  column-gap: 15px;

  margin-top: 30px;
}

.example {
  display: flex;
  justify-content: center;
}

.example img {
  max-width: 100%;
}

.additionalInfo {
  color: var(--palette-text-default);
}

.additionalInfo span {
  font-weight: 500;
}

@media (max-width: 992px) {
  .fileList {
    grid-template-columns: 100%;
  }

  .example {
    font-size: 26px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 24px;
  }
}

@media (max-width: 400px) {
  .container {
    padding: 20px 14px;
  }
}
