.slideContollers {
  display: flex;
  justify-content: center;
}

.slideController {
  width: 8px;
  height: 8px;
  margin-right: 15px;

  cursor: pointer;

  border-radius: 100%;
  background: var(--palette-primary-disabled);
}

.slideController:last-child {
  margin-right: 0;
}

.slideContollers .slideControllerActive {
  background: var(--palette-primary);
}
