.form {
  width: 100%;
  padding: 32px 16px;

  border-radius: 5px 10px 10px 5px;
  background: var(--palette-default);
}

.formButtons {
  margin-top: 15px;
}

.title {
  font-size: 22px;
  font-weight: 500;
  line-height: 130%;
}

.checkBoxChecked > div > div {
  border: none;
  background: var(--palette-primary);
}

.checkBoxChecked > div > div > svg * {
  fill: var(--palette-default);
}

/* Collapsible head */

.triggerCollapse {
  width: 100%;
}

.headSection {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  width: 100%;
  margin: 10px 0 0;

  text-align: left;

  border: 0;
  border-bottom: 1px solid var(--palette-primary-disabled);
  background: transparent;
}

.headSection:hover {
  background: var(--tab-hover-background);
}

.headSection:focus {
  background: var(--tab-hover-background);
}

.collapseArrow {
  margin-right: 28px;

  cursor: pointer;
  transition-duration: 150ms;
}

.collapseArrow.open {
  transform: rotate(180deg);
}

.entityName {
  flex: 1 1 auto;

  cursor: pointer;

  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  line-height: 19px;
}

/* Roles table form */

.responsiveTable {
  overflow-x: auto;

  padding: 0 15px;
}

.roleTable {
  width: 100%;
}

.roleTable thead td {
  white-space: nowrap;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  color: var(--palette-text-secondary);

  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  line-height: 130%;
}

.roleTable td {
  padding: 11px 10px;
}

.roleTable td:first-child {
  padding-left: 0;
}

.roleTable td:last-child {
  padding-right: 0;
}

.roleTable tbody td {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: 130%;
}

/* Table sizes */

.roleTable td:first-child {
  width: 200px;
}

/* Media */

@media (max-width: 768px) {
  .headSection > img {
    margin-right: 18px;
  }

  .entityName {
    font-size: 15px;
  }
}

@media (max-width: 560px) {
  .headSection > img {
    margin-right: 15px;
  }

  .entityName {
    font-size: 14px;
  }
}

@media (max-width: 420px) {
  .headSection :global .checkbox-container {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 15px;
  }
}
