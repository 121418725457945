.fade-enter {
  transform: scaleY(0.9) translate(20px);

  opacity: 0;
}

.fade-enter-active {
  transition: all 300ms ease-in !important;
  transform: scaleY(1) translate(0, 0);

  opacity: 1;
}

.fade-exit {
  transform: scaleY(1) translate(0, 0);

  opacity: 1;
}

.fade-exit-active {
  transform: scaleY(1) translate(0, 0);

  opacity: 1;
}
