.contentTooltip {
  max-width: 156px;
}

.applyAllText {
  color: var(--palette-text-primary);

  font-size: 12px;
  line-height: 1.5;
}

.tooltip {
  margin: 0;
}

.applyAllControl {
  display: flex;
  gap: 4px;

  margin-bottom: 24px;
}
