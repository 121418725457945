/* Form Item Modal */

.itemModal {
  position: relative;
}

.itemModalWrapTitle {
  text-transform: uppercase;

  color: var(--palette-text-secondary);

  font-weight: 600;
}

.itemModalHeader {
  display: flex;
  align-items: center;

  margin-bottom: 30px;

  white-space: nowrap;

  color: var(--palette-text-secondary);

  font-size: 22px;
  font-weight: 500;
  line-height: 130%;
}

.itemModalHeader .itemModalFileName {
  overflow: hidden;

  width: 100%;
  max-width: 300px;

  text-overflow: ellipsis;
}

.itemModalContentWrap {
  margin-top: 10px;
  margin-bottom: 15px;
}

.itemModalTableHeaderWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 10px;
}

.itemModalContentGridContainer {
  position: relative;

  min-height: 288px;
  max-height: 288px;
}

.itemModalContentGridContainer.hasItems {
  display: flex;

  padding: 6px 6px 0;

  border: 1px solid var(--palette-primary-disabled);
  border-radius: 10px 10px 3px 3px;
  background: var(--palette-tertiary-transparent);
  box-shadow: 0 0 3px var(--palette-shadow-small);
}

.itemModalContentGrid {
  display: grid;
  overflow-x: auto;

  min-width: 100%;
  margin-bottom: 1px;
  grid-auto-rows: 36px;
  row-gap: 3px;
}

.gridScrollBGRow {
  position: absolute;
  bottom: 0;
  left: 0;

  min-width: 100%;
  height: 6px;

  pointer-events: none;

  border-radius: 0 0 3px 3px;
}

.itemModalGridRow {
  display: flex;

  border-radius: 6px 0 0 6px;
  background-color: var(--palette-default);
}

.itemModalGridSelectsRow {
  background-color: var(--palette-default);
}

.itemModalGridRow.headerRow {
  letter-spacing: 0.03em;
  text-transform: uppercase;

  color: var(--palette-text-secondary);

  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  line-height: 130%;
}

.itemModalGridCell {
  display: flex;
  align-items: center;

  min-width: 160px;
  max-width: 160px;
  height: 36px;
  padding: 0 12px;

  word-break: break-word;
}

.itemModalGridSelectCell {
  padding: 3px 12px;
}

.itemModalGridCellText {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  overflow: hidden;
}

.itemModalTableHeaderHint {
  margin-right: 30px;
}

.itemModalTableHasHeaderWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemModalTableHasHeaderWrap > div {
  margin: 0 8px;
}

.itemModalTableHasHeaderWrap span {
  width: 24px;

  text-align: center;
}

.itemModalSuppressionWrap {
  margin-bottom: 36px;
}

.itemModalSuppressionTitle {
  margin-bottom: 10px;
}

.itemModalMessageFieldWrap {
  position: absolute;
  bottom: 36px;

  width: 100%;
}

.itemModalActionsWrap {
  display: flex;
  justify-content: flex-end;
}

.itemModalActionsWrap button:first-of-type {
  margin-right: 32px;
}

/* Status */

.default {
}

.success {
  border-color: var(--palette-success) !important;
}

.warning {
  border-color: var(--palette-warning) !important;
}

.error {
  border-color: var(--palette-error) !important;
}

/* States */

.noContent {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}
