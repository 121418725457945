.wrapper {
  padding: 48px;

  border-radius: 16px;
  background: var(--palette-default);
}

.wrapper:last-of-type {
  margin-top: 24px;
}

.descriptionWrapper {
  margin-bottom: 36px;
}

.title {
  margin-bottom: 12px;

  font-size: 16px;
  font-weight: 500;
  font-style: normal;
}

.list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}

.subtitle {
  color: var(--palette-text-secondary);
}

@media (max-width: 768px) {
  .list {
    grid-template-columns: 100%;

    padding: 24px;
  }
}

@media (max-width: 400px) {
  .list {
    padding: 20px 14px;
  }
}
