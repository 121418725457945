.container {
  padding: 48px;

  border-radius: 16px;
  background: var(--palette-default);
}

.uploadZone {
  margin-bottom: 30px;
}

.container .form {
  max-width: 600px;
  margin: 0 auto;
}

.fileList {
  display: grid;
  grid-template-columns: calc(50% - 5px) calc(50% - 5px);
  gap: 10px;
}

.buttons {
  display: flex;
  justify-content: flex-end;

  margin-top: 30px;
}

.buttons button:last-child {
  margin-left: 15px;
}

@media (max-width: 992px) {
  .fileList {
    grid-template-columns: 100%;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 24px;
  }
}

@media (max-width: 400px) {
  .container {
    padding: 20px 14px;
  }
}
