.container {
  padding: 48px;

  border-radius: 16px;
  background: var(--palette-default);
}

.uploadZone {
  margin-bottom: 30px;
}

.container .form {
  max-width: 1200px;
  margin: 0 auto;
}

.fileList {
  display: grid;
  grid-template-columns: calc(50% - 5px) calc(50% - 5px);
  gap: 10px;
}

.buttons {
  display: flex;
  justify-content: flex-end;

  margin-top: 30px;
}

.buttons button:last-child {
  margin-left: 15px;
}

.example {
  margin-bottom: 20px;

  text-align: center;

  color: var(--palette-text-secondary);

  font-size: 36px;
  font-weight: 300;
  font-style: normal;
  line-height: 130%;
}

.additionalInfo {
  margin-top: 20px;

  color: var(--palette-text-default);

  font-size: 13px;
  font-style: normal;
  line-height: 130%;
}

.additionalInfo span {
  font-weight: 500;
}

@media (max-width: 992px) {
  .fileList {
    grid-template-columns: 100%;
  }

  .example {
    font-size: 26px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 24px;
  }
}

@media (max-width: 400px) {
  .container {
    padding: 20px 14px;
  }
}
