.container {
  display: flex;
  flex-direction: row;

  width: 100%;
  max-width: 100%;
}

.content {
  position: relative;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  height: 100vh;

  transition: all 400ms ease 0ms;
}

@media (max-width: 992px) {
  .container {
    overflow-x: hidden;
  }

  .content {
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw !important;
  }
}
