html {
}

body {
  overflow-x: hidden;
  overflow-y: auto;

  width: 100%;
  max-width: 100%;
  height: 100vh;
  max-height: 100vh;
  margin: 0;
  padding: 0;

  background: var(--palette-body);

  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  line-height: 130%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.ReactModal__Body--open {
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;

  outline: none;

  font-family: "Poppins";
}

h2 {
  color: var(--palette-text-default);

  font-weight: 500;
  font-style: normal;
  line-height: 130%;
}
