.container {
  text-align: center;
}

.imgWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 76px;
  height: 76px;
  margin: 0 auto;

  border: 3px solid var(--palette-error);
  border-radius: 50%;
}

.img {
  width: 34px;
  height: 38px;
}

.title {
  margin: 16px 0;

  font-size: 22px;
  font-weight: 500;
}

.subtitle {
  margin-bottom: 24px;

  color: var(--palette-text-secondary);

  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
}

.value {
  color: var(--palette-text-link);
}

.button {
  width: 146px;
  margin: 0 auto;
}
