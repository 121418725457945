.container {
  display: flex;
  align-items: center;

  width: 100%;
  padding: 26px 48px 28px;
}

.spacer {
  flex: 1 1 auto;
}

.title {
  color: var(--palette-text-default);

  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  line-height: 130%;
}

.container button {
  width: 36px;
  margin-left: 24px;
  padding: 0;
}

/* Menu */

.menu {
  display: grid;

  padding: 4px;
}

.item {
  padding: 6px 20px;

  color: var(--palette-text-default);
  border: 0;
  border-radius: 8px;
  background: transparent;
  box-shadow: none;

  font-weight: 500;
}

.item:hover {
  background: var(--palette-default);
}

.item:focus,
.item:active {
  color: var(--palette-text-secondary);
}

/* Hamburger button */

.hamburger {
  display: none !important;
  flex-direction: column;
  justify-content: space-between;

  min-width: 36px;
  margin-right: 20px;
  margin-left: 0 !important;
  padding: 0 !important;
  gap: 0;
}

.open span:first-child {
  transform: rotate(45deg) translate(5px, 5px);
}

.open span:nth-child(2) {
  opacity: 0;
}

.open span:last-child {
  transform: rotate(-45deg) translate(4px, -5px);
}

@media (max-width: 992px) {
  .hamburger {
    display: flex !important;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 26px 24px 28px;
  }

  .title {
    font-size: 17px;
  }

  .container button {
    margin-left: 18px;
  }
}

@media (max-width: 520px) {
  .title {
    font-size: 15px;
  }
}

@media (max-width: 360px) {
  .title {
    font-size: 12px;
  }

  .container button {
    margin-left: 14px;
  }
}
